import React from 'react';
import styled from 'styled-components';
import {useOutlet, getOutlet} from 'reconnect.js';
import {Modal, Button, message, notification} from 'antd';
import * as AppActions from '../../AppActions';
import {TIERS, WIZARD_STEP_KEY} from '../../constants';

function Settings(props) {
  const [user, setUser] = useOutlet('user');
  const [changeTierModalContent, setChangeTierModalContent] = React.useState(
    null,
  );

  // if cannot find matched tier, assume to be basic tier
  const currTier =
    TIERS.find((t) => t.name === (user.tier || 'T1')) || TIERS[0];
  const nextTier =
    TIERS.find((t) => t.name === (user.next_tier || user.tier || 'T1')) ||
    TIERS[0];

  async function changeTier(nextChangeTier) {
    AppActions.setLoading(true);
    try {
      await AppActions.requestBillingChange(nextChangeTier.name);

      const curTierIdx = TIERS.findIndex((t) => t.name === user.tier);
      const nextChangeTierIdx = TIERS.findIndex(
        (t) => t.name === nextChangeTier.name,
      );
      const isUpgrade = nextChangeTierIdx >= curTierIdx;

      notification.success({
        key: 'change-tier-msg',
        message: `已完成切換等級`,
        description: isUpgrade
          ? `現在您可使用${nextChangeTier.display}的所有功能`
          : `提醒您，您的等級切換會在下月一號生效`,
        duration: null,
      });
      // update the next tier value in UserOutlet,
      if (isUpgrade) {
        setUser({
          ...user,
          tier: nextChangeTier.name,
          next_tier: nextChangeTier.name,
        });
      } else {
        setUser({
          ...user,
          next_tier: nextChangeTier.name,
        });
      }
    } catch (ex) {
      if (ex.response?.error === 'tier_not_found') {
        message.error('無法完成您的需求，請聯絡工程團隊');
      } else {
        message.error('無法完成您的需求，請再試一次');
      }
    }

    try {
      let wizardOutlet = getOutlet('setup-wizard');
      let {steps, curStepIdx, checkAchieve} = wizardOutlet.getValue();
      if (
        !steps.filter((s) => s.key === WIZARD_STEP_KEY.UPGRADE_PLAN)[0].achieve
      ) {
        await checkAchieve(WIZARD_STEP_KEY.UPGRADE_PLAN);
      }
    } catch (err) {
      //
      console.log(err);
    }
    AppActions.setLoading(false);
    setChangeTierModalContent(null);
  }

  return (
    <Wrapper>
      <div className="title">
        <h1>系統等級說明</h1>
        <p>
          *
          如需專人設定，可email至sales@bakershell.com.tw，每次設定費為新台幣400元
        </p>
      </div>
      <h2>會員等級</h2>

      <div className={'tiers-container'}>
        {TIERS.map((tier) => (
          <div
            className={`tier-item${
              currTier.name === tier.name ? ' selected' : ''
            }`}
            key={tier.name}>
            <img src={'/images/bakey.png'} alt="bakey" />
            <h3>{tier.display}</h3>
            <div className="price">${tier.price}</div>
            <p className="summary">{tier.description}</p>

            <ul>
              {tier.features.map((feat, idx) => (
                <li
                  key={idx}
                  className={
                    feat[0] === '+' ? 'yes' : 'no'
                  }>{`${feat[0]} ${feat[1]}`}</li>
              ))}
            </ul>

            {nextTier.name !== tier.name && (
              <Button
                size="large"
                type={'primary'}
                onClick={() => {
                  setChangeTierModalContent({from: nextTier, to: tier});
                }}>
                {tier.name === 'T1' ? '退回試用版' : '啟用正式版'}
              </Button>
            )}

            {
              // hint user that next tier is different from current tier
              nextTier.name === tier.name && currTier.name !== tier.name && (
                <p className="tier-note">下次等級</p>
              )
            }

            {currTier.name === tier.name && (
              <div className="selected-badge">目前等級</div>
            )}
          </div>
        ))}

        <div className="tier-item">
          <img src={'/images/bakey.png'} alt="bakey" />
          <h3>Bakershell Plus</h3>
          <div className="price" style={{fontSize: '2rem'}}>
            客製化定價
          </div>
          <p className="summary">完整功能 + 您的客製專屬功能</p>

          <ul>
            <li className="yes">+ 正式版完整功能</li>
            <li className="yes">+ 您的客製專屬功能</li>
          </ul>

          <div className="customize">
            <div>請來電:</div>
            <Button
              size="large"
              type="link"
              href="tel:0920691019"
              target="_blank"
              rel="noreferrer">
              0920691019 何先生
            </Button>

            <div style={{textAlign: 'center'}}>或是電子郵件寄送至:</div>
            <Button
              size="large"
              type="link"
              href="mailto:sales@bakershell.com.tw"
              target="_blank"
              rel="noreferrer">
              sales@bakershell.com.tw
            </Button>

            <div style={{textAlign: 'center'}}>竭誠為您服務!</div>
          </div>
        </div>
      </div>

      <Modal
        title={'會員等級切換'}
        closable={false}
        footer={null}
        bodyStyle={{padding: 0}}
        width={600}
        visible={changeTierModalContent}>
        {changeTierModalContent && (
          <TierChangeContentWrapper style={{padding: 20}}>
            <div style={{}}>
              您將從{changeTierModalContent.from.display} ${' '}
              {changeTierModalContent.from.price} /月
            </div>
            <div style={{}}>
              調整為{' '}
              <span className="highlight">
                {changeTierModalContent.to.display} $
                {changeTierModalContent.to.price} /月
              </span>
            </div>

            <div className="tier-privilege">
              更新後的會員功能如下
              <ul>
                {changeTierModalContent.to.features.map((feat, idx) => (
                  <li
                    key={idx}
                    className={
                      feat[0] === '+' ? 'yes' : 'no'
                    }>{`${feat[0]} ${feat[1]}`}</li>
                ))}
              </ul>
            </div>

            <p>是否確認變更會員等級？</p>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}>
              <Button onClick={() => setChangeTierModalContent(null)}>
                取消
              </Button>
              <Button
                style={{marginLeft: 10}}
                type="primary"
                onClick={() => changeTier(changeTierModalContent.to)}>
                確認
              </Button>
            </div>
          </TierChangeContentWrapper>
        )}
      </Modal>
    </Wrapper>
  );
}

const TierChangeContentWrapper = styled.div`
  & span.highlight {
    font-size: 20px;
    color: #ab906d;
  }

  & .tier-privilege {
    margin-top: 20px;
    margin-bottom: 20px;
    & ul {
      padding: 0;
      margin-left: 10px;
      margin-top: 5px;
      display: block;
      align-self: stretch;
      & li {
        list-style: none;
        margin-bottom: 10px;
      }
      & li.yes {
        color: #389e0d;
      }
      & li.no {
        color: #aaa;
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 20px;

  & > .title {
    & > h1 {
      font-size: 32px;
    }

    & > p {
      color: #aaa;
    }
  }

  & > h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  & .tiers-container {
    display: flex;
    flex-wrap: wrap;

    & > * {
      margin: 10px;
    }
  }

  & .tier-item {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 60px 20px 40px 20px;
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    transition: all 200ms;

    &.selected {
      border-width: 4px;
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    }

    & img {
      width: 40px;
      height: 40px;
      object-fit: contain;
      margin-bottom: 15px;
    }

    & .price {
      font-size: 2.5rem;
      font-weight: 500;
    }

    & ul {
      padding: 0;
      margin: 20px;
      display: block;
      align-self: stretch;
      & li {
        list-style: none;
        margin-bottom: 10px;
      }
      & li.yes {
        color: #389e0d;
      }
      & li.no {
        color: #aaa;
      }
    }

    & button {
      width: 100%;
    }

    & .tier-note {
      margin-top: 10px;
      color: #444;
    }

    & .selected-badge {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 6px 12px;
      border-radius: 15px;
      background-color: #d2b48c;
      color: white;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.9);
    }

    & .customize {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export default Settings;
